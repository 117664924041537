import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SectionTitle } from "../../../Shared/SectionTitle/SectionTitle";
import { theme } from "../../../Shared/Theme/Theme";
import { Container, Grid } from "@material-ui/core";
import {
  MostRequestedService,
  MostRequestedServiceProps
} from "./MostRequestedService";
import { useRequestedServicesImages } from "../hooks/useRequestedServicesImages";
import { routes } from "../../../../lib/routes";

export interface MostRequestedServicesProps {
  className?: string;
}

const MostRequestedServicesInner = (props: MostRequestedServicesProps) => {
  const { t } = useTranslation("translations");
  const images = useRequestedServicesImages();
  const mostRequestedServices: MostRequestedServiceProps[] = useMemo(
    () => [
      {
        ...t("services.coaching", { returnObjects: true }),
        image: images.chat,
        link: routes.servicesFolder.coaching.to
      },
      {
        ...t("services.therapy", { returnObjects: true }),
        image: images.person,
        link: routes.servicesFolder.therapy.to
      },
      {
        ...t("services.leadership", { returnObjects: true }),
        image: images.rocket,
        link: routes.servicesFolder.leadership.to
      }
    ],
    []
  );
  return (
    <div className={props.className}>
      <Container>
        <SectionTitle>{t("mostRequestedServicesBanner.title")}</SectionTitle>
        <Grid container spacing={8} direction="row">
          {mostRequestedServices.map((service: any) => (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              key={service.title}
              xs={12}
              md={4}
            >
              <MostRequestedService {...service} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export const MostRequestedServices = styled(MostRequestedServicesInner)`
  background: ${theme.color.sectionBg};
  padding: 40px 0;

  max-width: 100vw;
  overflow-x: hidden;
`;
