import { graphql, useStaticQuery } from "gatsby";

export const useHeroImage = () => {
  const { file } = useStaticQuery(graphql`
    query HeroImage {
      file(absolutePath: {}, relativePath: { eq: "Hero/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return file.childImageSharp.fluid;
};
