import React from "react";
import styled from "styled-components";
import { Container } from "@material-ui/core";
import { rgba } from "polished";
import { muiTheme, theme } from "../../../Shared/Theme/Theme";

export interface HeroTileProps {
  title: string;
  subtitle: string;
  className?: string;
}

const HeroTileInner = (props: HeroTileProps) => {
  const { title, subtitle } = props;
  return (
    <div className={props.className}>
      <Container>
        <h2 className="hero__big">{title}</h2>
        <h3 className="hero__small">{subtitle}</h3>
      </Container>
    </div>
  );
};

export const HeroTile = styled(HeroTileInner)`
  padding: 30px 0;
  text-align: center;
  background: ${rgba(theme.color.sectionBg, 0.6)};
  width: 100%;

  .hero {
    &__big {
      font-size: 6.4rem;
      font-weight: bold;
      margin-bottom: 10px;
      ${muiTheme.breakpoints.down("xs")} {
        font-size: 3.6rem;
      }
    }
    &__small {
      font-size: 3.6rem;
      font-weight: bold;
      ${muiTheme.breakpoints.down("xs")} {
        font-size: 2.8rem;
      }
    }
  }
`;
