import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { Button } from "../../../Shared/Button/Primary/Button";

export interface MostRequestedServiceProps {
  title: string;
  description: string;
  button: string;
  image: FluidObject;
  link: string;
  className?: string;
}

const RequestedServiceHeader = styled.h3`
  text-align: center;
  margin-bottom: 10px;
`;

const RequestedServiceSubHeader = styled.p`
  text-align: center;
`;

const ImageWrapper = styled.div`
  max-width: 60px;
  max-height: 65px;
  margin: 0 auto;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MostRequestedServiceInner = (props: MostRequestedServiceProps) => {
  const { title, description, button, image, link } = props;
  return (
    <Box
      className={`serviceInnerWrapper ${props.className}`}
      component="div"
      boxShadow={0}
      bgcolor="white"
      p={3}
      borderRadius={4}
      minHeight={270}
      display="flex"
      flexDirection="column"
    >
      <Grid item xs>
        <ImageWrapper>
          <GatsbyImage fluid={image} />
        </ImageWrapper>
        <RequestedServiceHeader>{title}</RequestedServiceHeader>
        <RequestedServiceSubHeader>{description}</RequestedServiceSubHeader>
      </Grid>
        <ButtonWrapper className="button-wrapper">
          <Link to={link}>
            <Button type="primary">{button}</Button>
          </Link>
        </ButtonWrapper>
    </Box>
  );
};

export const MostRequestedService = styled(MostRequestedServiceInner)`
  display: flex;
  flex: 1 1 auto;
  & ${Button} {
    margin-top: 20px;
    text-align: center;
  }
`;
