import BackgroundImage from "gatsby-background-image";
import React from "react";
import styled from "styled-components";
import { useHeroImage } from "./hooks/useHeroImage";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SvgBottom from "./assets/Bottom";
import Bottom from "./assets/bottom.svg";
import { HeroTile } from "./components/HeroTile.";
import { Button } from "../../Shared/Button/Primary/Button";
import { Link } from "gatsby";
import { routes } from "../../../lib/routes";
import { MostRequestedServices } from "./components/MostRequestedServices.";
import { Container, Hidden } from "@material-ui/core";

export interface HeroSectionProps {
  className?: string;
}

const HeroBottomSvg = styled.img`
  width: 100%;
  bottom: 0;
  position: absolute;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  min-height: 800px;
  position: relative;
`;

const CaptainText = styled.h2`
  font-family: "Grand Hotel", sans-serif;
  font-size: 56px;
  font-weight: 400;

  position: absolute;
  right: 0;
  bottom: -100px;
`;

const CaptainTextContainer = styled(Container)`
  position: relative;
`;

const HeroSectionInner = (props: HeroSectionProps) => {
  const heroImage = useHeroImage();
  const { t } = useTranslation("translations");
  return (
    <section className={props.className}>
      <StyledBackgroundImage fluid={heroImage}>
        <div className="content">
          <HeroTile
            title={t("hero.title.big")}
            subtitle={t("hero.title.small")}
          />
          <Hidden mdDown>
            <CaptainTextContainer>
              <CaptainText>{t("hero.motto")}</CaptainText>
            </CaptainTextContainer>
          </Hidden>
          <Link to={routes.whyWithMe.to}>
            <Button type="primary">{t("hero.callToActionButton")}</Button>
          </Link>
        </div>
        <HeroBottomSvg src={Bottom} alt="" />
      </StyledBackgroundImage>
      <MostRequestedServices />
    </section>
  );
};

export const HeroSection = styled(HeroSectionInner)`
  max-width: 100vw;
  overflow-x: hidden;

  .content {
    position: absolute;
    top: 50%;
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;

    ${Button} {
      margin-top: 20px;
    }
  }
`;
